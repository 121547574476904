<template src="./widget-activity.html"></template>

<script>
import Http from '@/shared/http-config';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Editor from '@tinymce/tinymce-vue';
import tinymce from '@/shared/tinymce';
import moment from 'moment'
import {nl2br} from '@/shared/utils.js'
import {mapFields} from "vuex-map-fields";

export default {
  name: "WidgetActivity",
  components: {'tinymceEditor': Editor, VuePerfectScrollbar},
  mixins: [tinymce],
  data(){
    return {
      nl2br: nl2br,
      moment: moment,
      activeTab: null,
      user: {},
      show_activity_response_editor: false,
      save_as_template: false,
      activities: [],
      branches: [],
      templates: [],
      appointmentTypes: [],
      editorOptions: {},
      meta: {
        current_page: 0,
        total: 1,
        per_page: 10,
      },
      new_activity: {
        subject: '',
        text: '',
        activity_response: {},
        email: {
          cc: [],
          attachments: [],
          new_attachments: [],
          request: {},
          template: {id: null},
          subject: '',
          text: '',
        },
        appointment: {
          start: moment().add(7, 'days').format('YYYY-MM-DD'),
          start_time: moment().add(7, 'days').format('kk:00'),
          end: '',
          type: {
            id: null
          }
        },
      },
    }
  },
  computed: {
    ...mapFields('customers', {
      customer: 'customer',
      customer_branch_id: 'customer.branch.id',
    }),
  },
  mounted: function () {
    let self = this;
    self.editorOptions = {
      height: 300,
      menubar: false,
      plugins: 'image link media imagetools lists',
      toolbar: 'undo redo | bold italic underline strikethrough | numlist bullist | removeformat | link | variable-select',
      language: 'de',
      language_url: 'https://static.fahrschul-system.de/tinymce/langs/de.js',
      default_link_target: "_blank",
      file_picker_callback: tinymce.methods.filePicker,
      automatic_uploads: true,
      images_upload_handler: tinymce.methods.upload,
      imagetools_toolbar: 'editimage imageoptions',
      setup: function (editor) {
        editor.ui.registry.addSplitButton('variable-select', {
          text: 'Variablen',
          onAction: function () {},
          onItemAction: function (api, value) {
            editor.insertContent(value);
          },
          fetch: function (callback) {
            let items = [];
            let variables = [
              '#schueler.vorname#','#schueler.nachname#','#user.name#','#user.signature#',
              '#filiale.name#','#filiale.strasse#','#filiale.postleitzahl#','#filiale.ort#',
              '#datum#','#uhrzeit#'
            ];
            variables.forEach(function (item) {
              items.push({
                type: 'choiceitem',
                text: item,
                value: item
              })
            });
            callback(items);
          }
        });
      }
    }
  },
  created() {
    let self = this;
    self.getActivities(false);
    self.getEmailTemplates();
    self.user = self.$store.getters.getUser;

    Http.request('GET', '/customers/appointment_types').then((appointmentTypes) => {
      self.appointmentTypes = appointmentTypes.data;
    });

    self.branches = self.$store.getters.get('branches');
  },
  watch: {
    '$route.params.id' (to, from) {
      if(parseInt(this.$route.params.id) > 0){
        this.refreshActivities();
      }
    }
  },
  methods: {
    getEmailTemplates(){
      let self = this;
      Http.request('GET', '/requests/templates').then((templates) => {
        self.templates = [
          {
            label: 'Eigene Vorlagen',
            options: templates.data.filter(item => item.owned === true)
              .map(item => { return { value: item, text: item.title }})
          },
          {
            label: 'GECCO Vorlagen',
            options: templates.data.filter(item => item.owned === false)
              .map(item => { return { value: item, text: item.title }})
          }
        ]
      });
    },
    onFileChange(e) {
      this.new_activity.email.new_attachments.push({
        file: e.target.files[0],
        name: e.target.files[0].name
      });
      e.target.value = '';
    },
    emailValidator(value){
      let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([üöäÜÖÄa-zA-Z\-0-9]+\.)+[üöäÜÖÄa-zA-Z]{2,}))$/;
      return pattern.test(String(value));
    },
    refreshActivities () {
      this.activities = [];
      this.meta = {
        current_page: 0,
        total: 1,
        per_page: 10,
      };
      this.getActivities(false);
    },
    getActivities (scroll) {
      let self = this;
      if(
        self.activities.length < self.meta.total &&
        (
          scroll === false && self.activities.length === 0 ||
          scroll === true && self.activities.length > 0
        )
      ){
        let params = '?page=' + (self.meta.current_page + 1) + '&per_page=' + self.meta.per_page;
        Http.request('GET', '/customers/' + self.$route.params.id + '/activities' + params).then(function (response) {
          self.activities = self.activities.concat(response.data);
          self.meta = response.meta;
        });
      }
    },
    activitySubject(activity) {
      let subject = '';
      if(activity.appointment){
        subject += activity.appointment.type.name + ' am: ' + this.$options.filters.formatDate(activity.appointment.start, 'DD.MM.YYYY HH:mm');
      }
      return subject;
    },
    activityAction(activity) {
      let action = '';
      if(activity.appointment){
        action = (activity.account ? activity.account.name + ' hat' : 'Es wurde') + ' einen Termin eingetragen';
      }
      else if(activity.email){
        action = (activity.account ? activity.account.name + ' hat' : 'Es wurde') + ' eine E-Mail gesendet';
      }
      else if (activity.request){
        action += 'Es wurde eine Webanfrage eingetragen';
      }
      else {
        action += (activity.account ? activity.account.name + ' hat' : 'Es wurde') + ' eine Notiz eingetragen';
      }
      return action;
    },
    activityIcon(activity) {
      if(activity.appointment){
        return 'cil-bell';
      }
      else if(activity.email){
        return 'cil-envelope-closed';
      }
      else if (activity.request){
        return 'cil-globe-alt';
      }
      else {
        return 'cil-notes';
      }
    },
    saveActivity (activity) {
      let self = this;
      Http.request('POST', '/customers/' + self.$route.params.id + '/activities', activity).then(function (response) {
        self.activities.unshift(response.data);
        Object.assign(self.$data.new_activity, self.$options.data().new_activity);
        self.$bvToast.toast('Aktivität im Verlauf hinterlegt.', {
          title: 'Aktivität gespeichert',
          variant: 'success',
          autoHideDelay: 5000
        });
      });
    },
    async saveNoticeActivity () {
      let isValid = await this.$refs.newActivityObserver.validate();
      if (isValid) {
        let activity = {};
        activity.subject = this.new_activity.subject;
        activity.text = this.new_activity.text;
        if(this.new_activity.appointment.type.id > 0){
          activity.appointment = {};
          activity.appointment.type = {};
          activity.appointment.type.id = this.new_activity.appointment.type.id;
          activity.appointment.start = this.new_activity.appointment.start + ' ' + this.new_activity.appointment.start_time;
          activity.appointment.end = this.new_activity.appointment.start + ' ' + this.new_activity.appointment.start_time;
        }

        if(this.new_activity.appointment.type.id === 1){
          await this.$store.dispatch('customers/storeCustomer');
        }
        this.saveActivity(activity);
      }
    },
    async saveEmailActivity () {
      let self = this;
      let isValid = await self.$refs.newEmailObserver.validate();
      if (isValid) {
        if(self.save_as_template){
          self.saveTemplate();
        }

        if(self.new_activity.email.request.id > 0){
          Http.request('POST', '/requests/' + self.new_activity.email.request.id + '/done/true').then(() => {
            self.$bvModal.hide('activity_response');
          });
        }

        let activityFormData = new FormData();
        activityFormData.append('subject', self.new_activity.email.subject);
        activityFormData.append('email[subject]', self.new_activity.email.subject);
        activityFormData.append('email[text]', self.new_activity.email.text);
        activityFormData.append('email[request]', self.new_activity.email.request);

        self.new_activity.email.attachments.forEach(function (attachment, key) {
          activityFormData.append('email[attachments][' + key + '][name]', attachment.name);
          activityFormData.append('email[attachments][' + key + '][file]', attachment.file);
        });
        self.new_activity.email.new_attachments.forEach(function (attachment, key) {
          activityFormData.append('email[new_attachments][' + key + '][name]', attachment.name);
          activityFormData.append('email[new_attachments][' + key + '][file]', attachment.file);
        });
        self.new_activity.email.cc.forEach(function (email, key) {
          activityFormData.append('email[cc][' + key + ']', email);
        });
        self.saveActivity(activityFormData);
      }
    },
    setRequestDone(activity){
      let self = this;
      let date = this.$options.filters.formatDate(activity.date);
      self.$bvModal.msgBoxConfirm('Möchtest Du diese(n) Anfrage wirklich als erledigt markieren?', {
          headerBgVariant: 'primary',
          title: 'Anfrage erledigen',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(value => {
          if(value === true){
            Http.request('POST', '/requests/' + activity.request.id + '/done/true').then(() => {
              let activity = {};
              activity.subject = 'Anfrage vom ' + date + ' als erledigt markiert.';
              activity.text = '';
              self.saveActivity(activity);
            });
          }
      })
    },
    requestResponse(activity){
      this.new_activity.activity_response = activity;
      this.new_activity.email.request = activity.request;
    },
    resetActivityResponse(){
      let self = this;
      Object.assign(self.$data.new_activity, self.$options.data().new_activity);
      self.$bvModal.hide('activity_response');
    },
    setEmailTemplate(template){
      let self = this;
      if(template.id === null){
        self.new_activity.email.template = {id: null};
        self.new_activity.email.subject = '';
        self.new_activity.email.text = '';
        self.new_activity.email.attachments = {};
      }
      else {
        self.new_activity.email.subject = self.replaceCustomerData(template.title);
        self.new_activity.email.text = self.replaceCustomerData(template.text);
        self.new_activity.email.attachments = template.attachments;
      }
    },
    saveTemplate(){
      let self = this;
      let templateFormData = new FormData();
      templateFormData.append('title', self.new_activity.email.subject);
      templateFormData.append('text', self.new_activity.email.text);
      self.new_activity.email.attachments.forEach(function (attachment, key) {
        templateFormData.append('attachments[' + key + '][id]', attachment.id);
        templateFormData.append('attachments[' + key + '][name]', attachment.name);
        templateFormData.append('attachments[' + key + '][file]', attachment.file);
      });
      self.new_activity.email.new_attachments.forEach(function (attachment, key) {
        templateFormData.append('new_attachments[' + key + '][name]', attachment.name);
        templateFormData.append('new_attachments[' + key + '][file]', attachment.file);
      });
      Http.request('POST', '/requests/templates/' + (self.new_activity.email.template.id > 0 ? self.new_activity.email.template.id : ''), templateFormData).then(() => {
        self.getEmailTemplates();
        self.$bvModal.hide('activity_response');
        Object.assign(self.$data.new_activity, self.$options.data().new_activity);

        self.$bvToast.toast('E-Mail wurde als Vorlage gespeichert.', {
          title: 'Vorlage gespeichert',
          variant: 'success',
          autoHideDelay: 5000
        });
      });
    },
    deleteTemplate(){
      let self = this;
      self.$bvModal.msgBoxConfirm('Möchtest du diese Vorlage unwiderruflich löschen?', {
        headerBgVariant: 'primary',
        title: 'Vorlage löschen',
        okTitle: 'Ja',
        cancelTitle: 'Nein',
        cancelVariant: 'outline-danger'
      })
      .then(value => {
        if(value === true){
          Http.request('DELETE', '/requests/templates/' + self.new_activity.email.template.id).then(() => {
            self.new_activity.email.template = {id: null};
            self.setEmailTemplate(self.new_activity.email.template);
            self.getEmailTemplates();
          });
        }
      });
    },
    deleteAppointment(activity){
      let self = this;
      let date = this.$options.filters.formatDate(activity.date);
      self.$bvModal.msgBoxConfirm('Möchtest Du diese(n) Wiedervorlage/Beratungstermin wirklich löschen?', {
        headerBgVariant: 'primary',
        title: 'Termin löschen',
        okTitle: 'Ja',
        cancelTitle: 'Nein',
        cancelVariant: 'outline-danger'
      })
      .then(value => {
        if(value === true){
          Http.request('DELETE', '/customers/' + self.$route.params.id + '/activities/' + activity.id).then(() => {
            let activity = {};
            activity.subject = 'Termin vom ' + date + ' wurde gelöscht.';
            activity.text = '';
            self.saveActivity(activity);
            self.refreshActivities();
          });
        }
      });
    },
    finishAppointment(activity){
      let self = this;
      let date = this.$options.filters.formatDate(activity.date);
      self.$bvModal.msgBoxConfirm('Ist diese(r) Wiedervorlage/Beratungstermin wirklich erledigt?', {
        headerBgVariant: 'primary',
        title: 'Termin erledigen',
        okTitle: 'Ja',
        cancelTitle: 'Nein',
        cancelVariant: 'outline-danger'
      })
      .then(value => {
        if(value === true){
          Http.request('DELETE', '/customers/' + self.$route.params.id + '/activities/' + activity.id).then(() => {
            let activity = {};
            activity.subject = 'Termin vom ' + date + ' wurde als erledigt markiert.';
            activity.text = '';
            self.saveActivity(activity);
            self.refreshActivities();
          });
        }
      });
    },
    replaceCustomerData(text){
      let self = this;
      text = text.replaceAll('#schueler.vorname#', self.customer.first_name);
      text = text.replaceAll('#schueler.nachname#', self.customer.last_name);
      text = text.replaceAll('#user.name#', self.user.user.name);
      text = text.replaceAll('#user.signature#', self.user.user.signature);

      if(self.customer.branch && self.customer.branch.id > 0){
        text = text.replaceAll('#filiale.name#', self.customer.branch.name);
        text = text.replaceAll('#filiale.strasse#', self.customer.branch.street);
        text = text.replaceAll('#filiale.postleitzahl#', self.customer.branch.postcode);
        text = text.replaceAll('#filiale.ort#', self.customer.branch.city);
      }

      let appointment = self.activities.filter(item => item.appointment && !item.appointment.canceled_at);
      if(appointment.length > 0){
        text = text.replaceAll('#datum#', moment(appointment[0].appointment.start).format('DD.MM.'));
        text = text.replaceAll('#uhrzeit#', moment(appointment[0].appointment.start).format('HH:mm'));
      }

      return text;
    }
  }
}
</script>

<style scoped>
</style>
