<template src="./profile.html"></template>

<script>
import moment from 'moment'
import Http from '@/shared/http-config'
import Editor from '@tinymce/tinymce-vue'
import tinymce from '@/shared/tinymce'
import {isMobile} from '@/shared/utils.js'
import copyToClipboard from '@/shared/copyToClipboard.js'
import StatusBox from '../components/StatusBox'
import WidgetActivity from '../components/WidgetActivity'
import { mapFields } from 'vuex-map-fields';
import {VueCroppieComponent} from 'vue-croppie'
import {permissionCheck} from '@/shared/permission';
import Multiselect from "vue-multiselect";
import store from "../../../Store.vue";

export default {
  name: "Profile",
  components: {Multiselect, 'tinymceEditor': Editor, StatusBox, WidgetActivity, 'vue-croppie': VueCroppieComponent},
  mixins: [tinymce, copyToClipboard],
  data: function () {
    return {
      document: document,
      new_picture: null,
      new_picture_error: false,
      courses: [],
      states: [],
      new_state: 0,
      camps: {
        fc: [],
        mc: []
      },
      user: {},
      companies: [],
    }
  },
  computed: {
    isMobile () {
      return isMobile();
    },
    package_variations () {
      return this.$store.getters['customers/get']('package_variations');
    },
    ...mapFields('customers', {
      customer: 'customer',
      customer_company: 'customer.company',
      customer_courses: 'customer.courses',
      customer_marketing_newsletter: 'customer.marketing.newsletter',
      customer_marketing_mailing: 'customer.marketing.mailing',
      customer_marketing_feedback_active: 'customer.marketing.feedback.active',
      customer_marketing_feedback_eKomi: 'customer.marketing.feedback.eKomi',
      customer_fc_course: 'customer.fc_course',
      customer_mc_course: 'customer.mc_course',
      customer_new_picture: 'customer.new_picture',
      education: 'education',
      education_passed_talk: 'education.passed_talk',
      education_tests_theory: 'education.tests.theory',
      education_tests_praxis: 'education.tests.praxis',
    }),
  },
  mounted: function(){
    this.$store.commit('set', ['containerFluid', true]);
  },
  destroyed: function(){
    this.$store.commit('set', ['containerFluid', false]);
  },
  created: function(){
    let self = this;
    self.getStates();

    Http.request('GET', '/courses?customer_id=' + self.$route.params.id).then((courses) => {
      self.courses = courses.data;
    });

    self.user = self.$store.getters.getUser;
    if(self.user.user_group === 1){
      Http.request('GET', '/user/company').then((results) => {
        self.companies = results.data.map(item => { return { value: item, text: item.name }});
      });
    }

    Http.request('GET', '/customers/camps/' + (self.$route.params.id > 0 ? self.$route.params.id : '')).then((camps) => {
      camps.data.forEach(function (camp) {
        self.camps[camp.type].push({
          value: camp,
          text: self.$options.filters.formatDate(camp.start) + ' - ' + self.$options.filters.formatDate(camp.end)
        });
      });
    });
  },
  beforeRouteEnter: function (to, from, next) {
    Promise.all([
      store.dispatch('fetchBranches'),
    ]).finally(() => {
      next((vm) => {})
    })
  },
  methods: {
    permissionCheck,
    onPictureChange(e) {
      let self = this;
      self.new_picture_error = false;
      let img = new Image();
      img.src = URL.createObjectURL(e.target.files[0]);
      img.onload = function() {
        let size = (img.naturalWidth < img.naturalHeight) ? img.naturalWidth : img.naturalHeight;
        if(size < 500){
          self.new_picture_error = true;
          return;
        }
        self.new_picture = e.target.files[0];
        self.$refs.croppieRef.bind({
          url: URL.createObjectURL(self.new_picture),
          zoom: 0
        });
      };
    },
    /* Todo: find solution for croppieRef in unit test */
    /* istanbul ignore next */
    savePicture() {
      let self = this;
      if(self.new_picture !== null) {
        let img = new Image();
        img.src = URL.createObjectURL(this.new_picture);
        img.onload = function() {
          let size = (img.naturalWidth < img.naturalHeight) ? img.naturalWidth : img.naturalHeight;
          let options = {
            type: 'blob',
            size: {
              width: size,
              height: size
            },
            format: 'jpeg'
          };
          self.$refs.croppieRef.result(options, output => {
            self.new_picture = output;
            self.customer.picture = URL.createObjectURL(self.new_picture);

            let formData = new FormData();
            formData.append('new_picture', self.new_picture);
            formData.appendRecursive(self.customer);

            self.$store.dispatch('customers/storeCustomer', formData).then(() => {
              self.new_picture = null;
              self.$bvModal.hide('new_picture-modal');
            });
          });
        };
      }
    },
    /* Todo: find solution for croppieRef in unit test */
    /* istanbul ignore next */
    rotate(rotationAngle) {
      this.$refs.croppieRef.rotate(rotationAngle);
    },
    getStates(){
      let self = this;
      let all_states = JSON.parse(JSON.stringify(self.$store.getters['customers/get']('states')));
      let states = {0:[],1:[],2:[]};
      all_states.forEach(state => {
        states[state.is_customer].push(state);
      });
      for (const [key, state] of Object.entries(states)) {
        if(parseInt(state[0].is_customer) < 2){
          // append first state of next type, for conversion
          state.push(states[parseInt(state[0].is_customer) + 1][0]);
        }
      }
      self.states = states;
    },
    statusChange(state){
      let self = this;
      Http.request('POST', '/customers/' + self.$route.params.id + '/educations/' + self.education.id + '/state/' + state.id).then(function (response) {
        self.$store.dispatch('customers/fetchEducations', self.$route.params.id).then(function(){
          self.education = response.data;
          self.$bvToast.toast('Status gespeichert', {
            title: 'Gespeichert',
            variant: 'success',
            autoHideDelay: 5000
          });
        });
      });
    },
    updateEducation(){
      let self = this;
      Http.request('POST', '/customers/' + self.$route.params.id + '/educations/' + self.education.id, self.education, false).then(() => {
        self.$bvToast.toast('Daten gespeichert', {
          title: 'Gespeichert',
          variant: 'success',
          autoHideDelay: 5000
        });
      });
    },
    resetFeedbackMail(){
      let self = this;
      self.$bvModal.msgBoxConfirm('Soll der Feedbackbogen heute Nacht erneut an den Schüler versendet werden?', {
          headerBgVariant: 'primary',
          title: 'Feedbackbogen erneut versenden?',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(value => {
          if (value === true) {
            Http.request('POST', '/customers/' + self.$route.params.id + '/feedback/reset', self.customer).then(function (response) {
              self.customer.marketing.feedback.mail_send = '0000-00-00';
            });
          }
        });
    },
    sendCustomerToDegner(){
      let self = this;
      self.$bvModal.msgBoxConfirm('Soll der Schüler zu Degener übertragen werden?', {
          headerBgVariant: 'primary',
          title: 'Zu Degner übertragen?',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(value => {
          if (value === true) {
            Http.request('POST', '/customers/' + self.$route.params.id + '/degener').then(function (response) {
              if(parseInt(response) > 0){
                self.$bvToast.toast('Schüler wurde zu Degner übertragen.', {
                  title: 'Übertragen',
                  variant: 'success',
                  autoHideDelay: 5000
                });
                self.customer.degner_disabled = true;
              }
            });
          }
        });
    },
    updateCustomer(){
      let self = this;

      if(self.customer.company && self.customer.company.id > 0){
        self.$bvModal.msgBoxConfirm('Durch eingabe des Kunden, verschwindet der Schüler aus ihrer Verwaltung! Fortsetzen?', {
          headerBgVariant: 'primary',
          title: 'Fortsetzen?',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(value => {
          if (value === true) {
            self.$store.dispatch('customers/storeCustomer').then(function () {
              self.$router.push('/customers/dashboard/' + self.$route.params.type);
            });
          }
          else {
            self.customer.company = null;
          }
        });
      }
      else {
        self.$store.dispatch('customers/storeCustomer').then(function () {
          self.$bvToast.toast('Daten gespeichert', {
            title: 'Gespeichert',
            variant: 'success',
            autoHideDelay: 5000
          });
        });
      }
    },
    stateSelected(key){
      let self = this;
      let selected = true;
      self.states[self.education.state.is_customer].forEach(state => {
        state.selected = selected;
        if(state.id === self.education.state.id){
          selected = false;
        }
      });
      return self.states[self.education.state.is_customer][key].selected;
    },
    saveEducationState(){
      let self = this;
      self.$store.dispatch('customers/saveEducationState', {
        customer_id: self.customer.id,
        education_id: self.education.id,
        new_status_id: self.new_state,
      })
      .then(function(){
        self.$bvModal.hide('abort_education');
        self.new_state = 0;
      });
    },
    copyFeedbackUrl(){
      let self = this;
      self.copyToClipboard('https://feedback.fahrschul-system.de/' + self.customer.uuid + '/' + self.customer.email).then(function (success) {
        if(success){
          self.$bvToast.toast('Der Feedbacklink wurde in die Zwischenablage kopiert', {
            title: 'Feedbacklink kopiert',
            variant: 'success',
            autoHideDelay: 5000
          });
        }
      })
    },
    sendCampContract(){
      let self = this;
      Http.request('GET', '/customers/camps/contract/' + self.customer.id).then(function (response) {
        self.$bvToast.toast('Der Vertrag wurde an den Schüler gesendet', {
          title: 'Versendet',
          variant: 'success',
          autoHideDelay: 5000
        });
      });
    },
    downloadContract(){
      let self = this;
      let filename = 'vertrag_' + self.customer.first_name + '_' + self.customer.last_name + '_' + moment().format('DDMMYYYY') + '.pdf';
      Http.requestFile('/customers/' + self.$route.params.id + '/educations/' + self.education.id + '/contract', filename, true);
    },
    courseLabel (course){
      let free_places = course.max_participants - course.num_participants;
      if(course.max_participants > 0){
        return this.$options.filters.formatDate(course.date, 'DD.MM.YY') + ' | 👥 ' + free_places + ' | ' + course.name;
      }
      else {
        return this.$options.filters.formatDate(course.date, 'DD.MM.YY') + ' | ' + course.name;
      }
    },
    updateCourses(){
      let self = this;
      self.$store.dispatch('customers/storeCustomer').then(function () {
        self.$bvToast.toast('Kurse gespeichert', {
          title: 'Gespeichert',
          variant: 'success',
          autoHideDelay: 5000
        });
      });
    }
  }
}
</script>

<style scoped>
.card-body {
  min-height: 218px;
}
</style>
